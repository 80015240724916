<template>
  <v-row class="row--35" align="center">
    <v-col lg="5" md="5" cols="12">
      <div class="thumbnail">
        <slot name="thum-img"></slot>
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner pt--100 pt_sm--40 pt_md--40">
        <div class="section-title">
          <h2 class="heading-title">{{ $t("about_me") }}</h2>
          <p class="description" v-html="description"/>
          <p class="description">{{ $t("more_info")}} <a :href="curriculum">{{ $t('curriculum')}}</a>.</p>
        </div>
        <!-- End .section-title -->
        <div class="tab-wrapper mt--30">
          <v-row>
            <TabTwo />
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabTwo from "../../components/tabs/TabTwo.vue";
export default {
  components: { TabTwo },
  props: ["description", "curriculum"],
  data() {
    return {};
  },
};
</script>
