var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--35",attrs:{"align":"start"}},[_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"2","order-md":"1"}},[_c('v-alert',{attrs:{"dismissible":"","type":_vm.alert.type},model:{value:(_vm.alert.active),callback:function ($$v) {_vm.$set(_vm.alert, "active", $$v)},expression:"alert.active"}},[_vm._v(_vm._s(_vm.$t(_vm.alert.text))+" ")]),_c('div',{staticClass:"section-title text-left mb--50 mb_sm--30 mb_md--30"},[_c('h2',{staticClass:"heading-title"},[_vm._v(_vm._s(_vm.$t("contact.title")))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t("contact.description")))])]),_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":_vm.$t('name') + ' *'},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Email *"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subject),expression:"formData.subject"}],attrs:{"type":"text","placeholder":_vm.$t('subject') + ' *'},domProps:{"value":(_vm.formData.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subject", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":_vm.$t('message') + ' *'},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"rn-button-style--2 btn_solid",attrs:{"type":"submit","value":"submit"}},[(_vm.sending)?_c('div',[_c('i',{staticClass:"fas fa-spinner fa-pulse fa-2x"})]):_c('div',[_vm._v(_vm._s(_vm.$t("submit")))])])],1)]}}])})],1)],1),_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12","cols":"12","order":"2","order-md":"2"}},[_c('v-row',{},[_c('v-col',{staticClass:"mt--40",attrs:{"sm":"12","cols":"12"}},[_c('div',{staticClass:"rn-address bg_color--5"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-envelope"})]),_c('div',{staticClass:"inner"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("email_address")))]),_c('p',[_c('a',{attrs:{"href":"mailto:admin@gmail.com"}},[_vm._v("marvincorreia.dev@gmail.com")])])])])]),_c('v-col',{staticClass:"mt--40",attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"rn-address bg_color--5"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-phone"})]),_c('div',{staticClass:"inner"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("phone_number")))]),_c('p',[_c('a',{attrs:{"href":"tel:+057254365456"}},[_vm._v("+238 9869166")])])])])]),_c('v-col',{staticClass:"mt--40",attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"rn-address bg_color--5"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-map-marker-alt"})]),_c('div',{staticClass:"inner"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("location")))]),_c('p',[_vm._v("Mindelo 2115, SV, Cabo Verde")])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }