<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ $t(item.name) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(itemInner, i) in item.content" :key="i">
                    <!-- skills -->
                    <div v-if="item.id === 1">
                      <router-link to="">
                        {{ itemInner.translations[language].title }}
                        <span v-html="` - ${itemInner.translations[language].name}`" />
                      </router-link>
                      {{ itemInner.translations[language].description }}
                    </div>
                    <!-- experience -->
                    <div v-else-if="item.id === 2">
                      <router-link to="">
                        {{ itemInner.translations[language].title }}
                        <span v-html="` - ${itemInner.company}`" />
                      </router-link>
                      {{ `${itemInner.initial_date} | ${itemInner.end_date || "Present"}` }}
                    </div>
                    <!-- certification -->
                    <div v-else-if="item.id === 3">
                      <router-link to="">
                        {{ itemInner.translations[language].name }}
                        <span v-html="` - ${itemInner.translations[language].title}`" />
                      </router-link>
                     {{ itemInner.translations[language].year }}
                    </div>
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>

<script>
import {
  ref,
  computed,
  reactive,
  onMounted,
  watch,
} from "@vue/composition-api";
import api from "../../services/api";
import axios from "axios";
export default {
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);
    // const langFilter = computed(() => `?language=${language.value}`);
    const tab = ref(null);
    const items = ref([
      // name must be equal translation key
      { id: 1, name: "main_skills" },
      { id: 2, name: "experience" },
      { id: 3, name: "education" },
    ]);

    const tabContent = ref([]);

    function normilizeSkills(skills) {
      return skills
      // return skills.map((x) => {
      //   return { title: x.title, name: x.name, desc: x.description };
      // });
    }

    function normilizeCerts(certs) {
      return certs
      // return certs.map((x) => {
      //   return { title: x.title, name: x.name, desc: x.year };
      // });
    }

    function normilizeExperiences(exps) {
      return exps
      // return exps.map((x) => {
      //   return {
      //     title: x.title,
      //     name: x.company,
      //     desc: `${x.initial_date} | ${x.end_date || "Current"}`,
      //   };
      // });
    }

    function fetchData() {
      axios
        .all([
          api.get(`/skill`),
          api.get(`/experience`),
          api.get(`/certification`),
        ])
        .then(
          axios.spread((...responses) => {
            // use/access the results
            tabContent.value = [];
            tabContent.value.push({
              id: 1,
              content: normilizeSkills(responses[0].data.results),
            });
            tabContent.value.push({
              id: 2,
              content: normilizeExperiences(responses[1].data.results),
            });
            tabContent.value.push({
              id: 3,
              content: normilizeCerts(responses[2].data.results),
            });
          })
        )
        .catch((errors) => {
          // react on errors.
        });
    }

    // watch(language, () => {
    //   fetchData();
    // });

    onMounted(() => {
      fetchData();
    });

    return {
      tab,
      tabContent,
      items,
      language,
    };
  },
};
</script>
