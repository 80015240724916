<template>
  <ul
    class="social-share social-style--2 color-black d-flex justify-content-start liststyle"
  >
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.url" target="_blank"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
  import { computed } from '@vue/composition-api'
  export default {
    setup(props, {root}){
      const store = root.$store
      const socialList = computed(() => {
        const portfolio = store.state.portfolio.data
        return [
          { icon: "fa-facebook-f", url: portfolio.facebook, },
          { icon: "fa-linkedin-in", url: portfolio.linkedin, },
          { icon: "fa-twitter", url: portfolio.twitter, },
          { icon: "fa-instagram", url: portfolio.instagram, },
        ]
      })

      return {
        socialList,
      }
    }
  }
</script>
