<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <v-alert dismissible v-model="alert.active" :type="alert.type"
        >{{ $t(alert.text) }}
      </v-alert>
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">{{ $t("contact.title") }}</h2>
        <p class="description">{{ $t("contact.description") }}</p>
      </div>
      <div class="form-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  :placeholder="$t('name') + ' *'"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Email *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  :placeholder="$t('subject') + ' *'"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea
                  v-model="formData.message"
                  :placeholder="$t('message') + ' *'"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
            >
              <div v-if="sending">
                <i class="fas fa-spinner fa-pulse fa-2x"></i>
              </div>
              <div v-else>{{ $t("submit") }}</div>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="2">
      <v-row class="">
        <!-- Start Single Address  -->
        <v-col sm="12" cols="12" class="mt--40">
          <div class="rn-address bg_color--5">
            <div class="icon">
              <i class="fas fa-envelope"></i>
            </div>
            <div class="inner">
              <h4 class="title">{{ $t("email_address") }}</h4>
              <p>
                <a href="mailto:admin@gmail.com">marvincorreia.dev@gmail.com</a>
              </p>
            </div>
          </div>
        </v-col>
        <!-- End Single Address  -->

        <!-- Start Single Address  -->
        <v-col sm="6" cols="12" class="mt--40">
          <div class="rn-address bg_color--5">
            <div class="icon">
              <i class="fas fa-phone"></i>
            </div>
            <div class="inner">
              <h4 class="title">{{ $t("phone_number") }}</h4>
              <p>
                <a href="tel:+057254365456">+238 9869166</a>
              </p>
            </div>
          </div>
        </v-col>
        <!-- End Single Address  -->

        <!-- Start Single Address  -->
        <v-col sm="6" cols="12" class="mt--40">
          <div class="rn-address bg_color--5">
            <div class="icon">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="inner">
              <h4 class="title">{{ $t("location") }}</h4>
              <p>Mindelo 2115, SV, Cabo Verde</p>
            </div>
          </div>
        </v-col>
        <!-- End Single Address  -->
      </v-row>
      <!-- <div class="thumbnail mb_md--40 mb_sm--40">
        <img src="../../assets/images/about/about-6.jpg" alt="trydo" />
      </div> -->
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { reactive, ref } from "@vue/composition-api";
import api from "../../services/api";
import i18n from "../../plugins/i18n";
import feather from "feather-icons";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const initialFormData = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
    const formData = reactive({ ...initialFormData });
    const sending = ref(false);
    const alert = reactive({
      type: "success",
      active: false,
      text: "",
    });

    return {
      formData,
      initialFormData,
      sending,
      alert,
    };
  },
  methods: {
    resetForm() {
      Object.assign(this.formData, this.initialFormData);
      this.$refs.observer.reset();
    },
    onSubmit() {
      if (this.sending) return;

      const data = JSON.parse(JSON.stringify(this.formData));
      this.sending = true;
      api
        .post("/send_email/", data)
        .then((response) => {
          this.resetForm();
          this.sending = false;
          this.alert.text = "send_email.success";
          this.alert.type = "success";
          this.alert.active = true;
        })
        .catch((e) => {
          console.log(e);
          this.sending = false;
          this.alert.text = "send_email.error";
          this.alert.type = "error";
          this.alert.active = true;
        });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
