<template>
  <v-row>
    <v-col cols="12">
      <div class="slick-space-gutter--15">
        <VueSlickCarousel
          v-bind="settings"
          class="rn-slick-activation slick-dotted rn-slick-dot pb--25"
          v-if="portfolioContent.length > 0"
        >
          <!-- Start Blog Area  -->
          <div
            class="blog blog-style--1"
            v-for="(item, i) in portfolioContent"
            :key="i"
          >
            <div class="thumbnail">
              <router-link
                :to="{
                  name: 'PortfolioDetails',
                  params: { slug: item.slug },
                }"
              >
                <img
                  class="w-100"
                  :src="item.cropped_image"
                  alt="Blog Images"
                />
              </router-link>
            </div>
            <div class="content">
              <p class="blogtype">{{ item.name }}</p>
              <h4 class="heading-title">
                <router-link
                  :to="{
                    name: 'PortfolioDetails',
                    params: { slug: item.slug },
                  }"
                  >{{ item.translations[language].title }}
                </router-link>
              </h4>
              <div class="blog-btn">
                <router-link
                  class="rn-btn white--text"
                  :to="{
                    name: 'PortfolioDetails',
                    params: { slug: item.slug },
                  }"
                  >Read More
                </router-link>
              </div>
            </div>
          </div>
          <!-- End Blog Area  -->
        </VueSlickCarousel>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { computed } from "@vue/composition-api";
export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    portfolioContent: { type: Array },
  },
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);

    return {
      language,
    };
  },
  data() {
    return {
      // for all works
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 890,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 490,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
