<template>
  <div class="active-dark" v-if="portfolio.translations">
    <!-- Start Header Area -->
    <HeaderOnePageTwo />
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-slider-area poss_relative" id="home">
      <div
        class="slide personal-portfolio-slider slider-style-3 slider-paralax bg_image bg_image--25 d-flex align-center"
      >
        <BannerThree>
          <span slot="designation">{{
            portfolio.translations[language].role
          }}</span>
          <!-- {{ portfolio.role }} -->
          <h1
            class="heading-title"
            slot="heading-title"
            v-html="portfolio.translations[language].title"
          >
            <!-- Hello, I’m <span>Marvin</span> Welcome to my World. -->
            <!-- {{ portfolio.title }} -->
          </h1>
        </BannerThree>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="section about-area rn-section-gap bg_color--1" id="about">
      <div class="about-wrapper">
        <div class="container">
          <AboutFour
            :description="portfolio.translations[language].about_me"
            :curriculum="portfolio.translations[language].cv"
          >
            <img
              slot="thum-img"
              class="w-100"
              src="../../assets/images/about/about-8.jpg"
              alt="About Images"
            />
          </AboutFour>
        </div>
      </div>
    </div>
    <!-- End About Area  -->

    <!-- Start skills Feature Area  -->
    <div class="prv-feature service-area bg_color--4 ptb--120" id="skills">
      <div class="wrapper plr--120 plr_sm--30 plr_md--30 plr_lg--30">
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center pb--30 pb_sm--0 pb_md--0">
              <h2 class="white--text">{{$t('featured_skills')}}</h2>
            </div>
          </v-col>
        </v-row>
        <div class="row service-main-wrapper prev-service-main-wrapper">
          <!-- Start Single Feature  -->
          <v-col
            xl="3"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(features, i) in featuresContent"
            :key="i"
            class="mt--30"
          >
            <div class="single-service service__style--4">
              <div class="service">
                <div class="icon" v-html="iconSvg(features.icon)"></div>
                <div class="content">
                  <h3 class="heading-title">{{ features.title }}</h3>
                  <p class="subtitle">
                    {{ features.desc }}
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <!-- End Single Feature  -->
        </div>
      </div>
    </div>
    <!-- End Feature Area  -->

    <!-- Start Service Area  -->
    <div
      class="section service-area rn-service-area rn-section-gap bg_color--5"
      id="service"
    >
      <div class="container">
        <v-row>
          <v-col lg="12">
            <div
              class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0"
            >
              <h2 class="heading-title">{{ $t("services.title") }}</h2>
              <p>{{ $t("services.description") }}</p>
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </div>
    </div>
    <!-- End Service Area  -->

    <!-- Start Portfolio Area -->
    <div
      class="section rn-portfolio-area rn-section-gap bg_color--1"
      id="portfolio"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title section-title--2 text-center mb--20">
              <h2 class="heading-title">{{ $t("projects.title") }}</h2>
              <p>
                {{ $t("projects.description") }}
              </p>
            </div>
          </v-col>
        </v-row>
        <PortfolioFour :portfolioContent="portfolioContent" />
        <v-row>
          <v-col lg="12">
            <div class="view-more-btn mt--60 mt_sm--30 text-center">
              <router-link to="/portfolio" class="rn-button-style--2 btn_solid">
                {{ $t("view_more") }}
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Blog Area  -->
    <div class="section rn-blog-area rn-section-gap bg_color--5" id="blog">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="12">
            <div class="section-title text-left">
              <h2>{{ $t("latest_posts.title") }}</h2>
              <p>
                {{ $t("latest_posts.description") }}
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- <Blog /> -->
        <v-row>
          <v-col lg="12">
            <div class="view-more-btn mt--60 mt_sm--30 text-center">
              <!-- <router-link to="/blog" class="rn-button-style--2 btn_solid">
                {{ $t("view_more") }}
              </router-link> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Area  -->

    <!-- Start Contact Area  -->
    <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-9.jpg"
              alt="Marvin Correia"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <!-- Start Contact Address Area  -->
    <!-- <div class="rn-contact-address-area rn-section-gap bg_color--1">
      <v-container>
        <ContactAddress />
      </v-container>
    </div> -->
    <!-- End Contact Address Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo.vue";
import BannerThree from "../../components/slider/BannerThree.vue";
import AboutFour from "../../components/about/AboutFour.vue";
import ServiceThree from "../../components/service/ServiceThree.vue";
import PortfolioFour from "../../components/portfolio/PortfolioTen.vue";
import Blog from "../../components/blog/BlogTwo.vue";
import Contact from "../../components/contact/Contact.vue";
import Footer from "../../components/footer/FooterTwo.vue";
import ContactAddress from "../../components/contact/ContactAddress.vue";

import { computed, reactive, onMounted } from "@vue/composition-api";
import api from "../../services/api";
import feather from "feather-icons";
export default {
  setup(props, { root }) {
    const store = root.$store;
    const portfolio = computed(() => store.state.portfolio.data);
    const language = computed(() => store.state.app.language);
    const portfolioContent = reactive([]);
    const featuresContent = reactive([
      {
        icon: "smartphone",
        title: "Django",
        desc: `The high-level Python web framework that encourages rapid development`,
      },
      {
        icon: "cast",
        title: "Python",
        desc: `Programming language that lets you work quickly and integrate systems more effectively`,
      },
      {
        icon: "smartphone",
        title: "VueJS",
        desc: `No.1 Github Start & Developer Friendly Top Progressive JavaScript Framework `,
      },
      {
        icon: "command",
        title: "Docker",
        desc: `The open source containerization engine. Package and deploy applications into containers`,
      },
      {
        icon: "command",
        title: "Gitlab",
        desc: `The one devops platform that helps you automate the builds, integration, and verification of your code`,
      },
      {
        icon: "command",
        title: "Kubernetes",
        desc: `The open-source system for automating deployment, scaling, and management of containerized applications.`,
      },
      {
        icon: "command",
        title: "Jenkins",
        desc: `The leading open source automation server which enables reliably build, test, and deploy.`,
      },
      {
        icon: "command",
        title: "Ansible",
        desc: `The radically simple IT automation system for building and operating IT automation at scale.`,
      },
    ]);

    function fetchData() {
      api
        .get(`/project?featured=true&active=true`)
        .then((response) => {
          response.data.results.map((x) => {
            if (x.image.includes(".gif")) {
              x.cropped_image = x.image;
            }
            return x;
          });
          portfolioContent.splice(0);
          portfolioContent.push(...response.data.results);
        })
        .catch((err) => {
          console.error("cannot get projects");
          console.log(err);
        });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      portfolio,
      language,
      portfolioContent,
      featuresContent,
    };
  },
  components: {
    HeaderOnePageTwo,
    BannerThree,
    AboutFour,
    ServiceThree,
    PortfolioFour,
    Blog,
    Contact,
    Footer,
    ContactAddress,
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
