<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <!-- <router-link to="/service-details"> -->
        <router-link to="">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.translations[language].title }}</h3>
              <p>
                {{ service.translations[language].description }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import { reactive, watch, computed, onMounted } from "@vue/composition-api";
import api from "../../services/api";
import feather from "feather-icons";
export default {
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);
    const serviceContent = reactive([]);

    function fetchData(){
      api.get(`/service`).then((response) => {
        serviceContent.splice(0)
        serviceContent.push(...response.data.results)
      }).catch((err) => {
        console.error("cannot get services")
      })
    }

    fetchData()

    return {
      serviceContent,
      language
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
